import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

export default (props) => {
  return (
    <StaticQuery
      query={graphql`
        query VenueFilterQuery {
          aCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^A/i"}}) {
            totalCount
          }
          bCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^B/i"}}) {
            totalCount
          }
          cCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^C/i"}}) {
            totalCount
          }
          dCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^D/i"}}) {
            totalCount
          }
          eCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^E/i"}}) {
            totalCount
          }
          fCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^F/i"}}) {
            totalCount
          }
          gCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^G/i"}}) {
            totalCount
          }
          hCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^H/i"}}) {
            totalCount
          }
          iCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^I/i"}}) {
            totalCount
          }
          jCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^J/i"}}) {
            totalCount
          }
          kCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^K/i"}}) {
            totalCount
          }
          lCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^L/i"}}) {
            totalCount
          }
          mCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^M/i"}}) {
            totalCount
          }
          nCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^N/i"}}) {
            totalCount
          }
          oCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^O/i"}}) {
            totalCount
          }
          pCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^P/i"}}) {
            totalCount
          }
          qCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^Q/i"}}) {
            totalCount
          }
          rCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^R/i"}}) {
            totalCount
          }
          sCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^S/i"}}) {
            totalCount
          }
          tCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^T/i"}}) {
            totalCount
          }
          uCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^U/i"}}) {
            totalCount
          }
          vCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^V/i"}}) {
            totalCount
          }
          wCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^W/i"}}) {
            totalCount
          }
          xCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^X/i"}}) {
            totalCount
          }
          yCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^Y/i"}}) {
            totalCount
          }
          zCount: allVenuesJson(
            filter: {reviewed: {eq: true}, canonical_name: {regex: "/^Z/i"}}) {
            totalCount
          }
        }
      `}
      render={data => {
        const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
                         'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 
                         'u', 'v', 'w', 'x', 'y', 'z'];
        
        return (
          <div className="alpha-filter">
            <div className="alpha-filter__cell">
              {(props.filtered === 'all') ? (
                <Link className="alpha-filter__filtered" to="/venues">All</Link>
              ) : (
                <Link to="/venues">All</Link>
              )}
            </div>
            {letters.map(letter => {
              return (
                <div key={letter} className="alpha-filter__cell">
                  {(data[letter + 'Count']) ? (
                    <>
                      {(letter === props.filtered) ? (
                        <Link className="alpha-filter__letter-link alpha-filter__filtered" to={`/venues/${letter}`}>{letter}</Link>
                      ) : (
                        <Link className="alpha-filter__letter-link" to={`/venues/${letter}`}>{letter}</Link>
                      )}
                    </>
                  ) : (
                    <span className="alpha-filter__empty">{letter}</span>
                  )}
                </div>
              );
            })}
          </div>
        );
      }}
    />
  );
}